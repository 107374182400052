import * as React from "react"
import background from '../assets/media/background.jpeg';
import {graphql} from "gatsby";
import Navigation from "../components/navigation";
import SEO from "../components/seo";

export const query = graphql`
    query IndexQuery {
        datoCmsHomePage {
            title
            content
        }
    }
`

const IndexPage = ({data}) => {
    const {
        datoCmsHomePage: {
            title,
            content
        },
    } = data

    return (
        <>
            <SEO title={title}/>
            <Navigation/>
            <div className={'home-page'} style={{backgroundImage: `url(${background})`}}>
                <div className={'container h-100'}>
                    <div className={'h-100'}>
                        <div className={'content'}>
                            <div dangerouslySetInnerHTML={{__html: content}}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IndexPage
